import React, { useState } from "react";
import supabase from "./../supabase";

import CATEGORIES from "./CATEGORIES";

function Fact({ fact, setFacts }) {
  const [isUpdating, setIsUpdating] = useState(false);
  const isDisputed =
    fact.votesInteresting + fact.votesMindblowing < fact.votesFalse;

  async function handleVote(columnName) {
    setIsUpdating(true);
    const { data: updatedFact, error } = await supabase
      .from("facts")
      .update({ [columnName]: fact[columnName] + 1 })
      .eq("id", fact.id)
      .select();
    setIsUpdating(false);

    if (!error) {
      setFacts((facts) =>
        facts.map((f) => (f.id === fact.id ? updatedFact[0] : f))
      );
    }
  }

  return (
    <li className="fact">
      <p>
        {isDisputed ? (
          <span className="disputed" role="img" aria-label="disputed">
            [⛔ DISPUTED]
          </span>
        ) : null}
        {fact.text}
        <a
          className="source"
          href="{fact.source}"
          target="_blank"
          rel="noreferrer"
        >
          (Source)
        </a>
      </p>
      <span
        className="tag"
        style={{
          backgroundColor: CATEGORIES.find(
            (category) => category.name === fact.category
          ).color,
        }}
      >
        {fact.category}
      </span>
      <div className="vote-buttons">
        <button
          onClick={() => handleVote("votesInteresting")}
          disabled={isUpdating}
        >
          <span role="img" aria-label="emoji">
            👍
          </span>{" "}
          {fact.votesInteresting}
        </button>
        <button
          onClick={() => handleVote("votesMindblowing")}
          disabled={isUpdating}
        >
          <span role="img" aria-label="emoji">
            🤯
          </span>{" "}
          {fact.votesMindblowing}
        </button>
        <button onClick={() => handleVote("votesFalse")} disabled={isUpdating}>
          <span role="img" aria-label="emoji">
            ⛔
          </span>{" "}
          {fact.votesFalse}
        </button>
      </div>
    </li>
  );
}

export default Fact;
