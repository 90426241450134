import React from "react";

import Category from "./Category";
import CATEGORIES from "./CATEGORIES";

function CategoryFilter({ setCurrentCategory }) {
  return (
    <aside>
      <ul className="category-list">
        <li className="category">
          <button
            className="btn btn-all-categories"
            onClick={() => setCurrentCategory("all")}
          >
            All
          </button>
        </li>

        {CATEGORIES.map((category) => (
          <Category
            key={category.name}
            cat={category}
            setCurrentCategory={setCurrentCategory}
          />
        ))}
      </ul>
    </aside>
  );
}

export default CategoryFilter;
