import React from "react";

import Fact from "./Fact";

function FactList({ facts, setFacts }) {
  if (facts.length === 0) {
    return (
      <p className="message">
        No facts for this category yet! Create the first one{" "}
        <span role="img" aria-label="emoji">
          🖊️
        </span>
      </p>
    );
  } else {
    return (
      <section>
        <ul className="facts-list">
          {facts.map((fact) => (
            <Fact key={fact.id} fact={fact} setFacts={setFacts} />
          ))}
        </ul>

        <p>There are {facts.length} facts in the database.</p>
        <h4>Build by Moli.</h4>
      </section>
    );
  }
}

export default FactList;
